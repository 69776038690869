import Vue from "vue";
import App from "./App.vue";
import "default-passive-events";
// import "./permission"; // permission control
import "@/assets/styles/ruoyi.scss"; // ruoyi css
import router from "./router";
import store from "./store";
// import 'default-passive-events'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "swiper/dist/css/swiper.css";
import axios from "axios";
import Pagination from "@/components/Pagination";
// import "@/utils/rem"
import "@/utils/bdtj.js";
import ClipboardJS from 'clipboard';
import { Message } from "element-ui";


import { MessageBox } from "element-ui";
import hljs from "highlight.js";
import "highlight.js/styles/github.css"; //黑色主题，会好看一点

Vue.directive("hljs", {
  inserted(el) {
    let blocks = el.querySelectorAll("pre code");
    blocks.forEach((block) => {
      // 检测代码块的类别
      let lang = block.className.toLowerCase().replace('language-', '');
      if (lang === 'vue') {
        lang = 'javascript'; // 将vue代码块视为javascript
        block.className = 'language-javascript'; // 确保遵循highlight.js的类名约定
      }

      // 使用highlight.js进行高亮
      hljs.highlightBlock(block);

      // 识别反引号包裹的内容并添加class属性
      // let codeContent = block.innerHTML;
      // codeContent = codeContent.replace(/`([^`]+)`/g, '<span class="backtick">$1</span>');
      // block.innerHTML = codeContent;

      // 创建包含语言标签和复制按钮的容器
      const controlPanel = document.createElement("div");
      controlPanel.className = "code-control-panel";

      // 添加语言标签
      const langLabel = document.createElement("span");
      langLabel.textContent = lang.toUpperCase();
      langLabel.className = "code-lang-label";
      controlPanel.appendChild(langLabel); // 将语言标签添加到控制面板

      // 添加复制按钮
      const copyBtn = document.createElement("div");
      copyBtn.textContent = "copy";
      copyBtn.className = "copy-code-btn";
      copyBtn.onclick = function() {
        const codeText = block.textContent;
        const clipboard = new ClipboardJS(copyBtn, {
          text: function() {
            return codeText;
          }
        });
        clipboard.on('success', function(e) {
          Message({ message: '复制成功', type: "success" });
          clipboard.destroy();
        });
      };
      controlPanel.appendChild(copyBtn); // 将复制按钮添加到控制面板

      // 将控制面板插入到code块的前面
      block.parentNode.insertBefore(controlPanel, block);
    });
  }
});
Vue.prototype.$confirm = MessageBox.confirm;

// axios.defaults.baseURL = 'http://192.87.18.113:8080'
axios.defaults.baseURL = "/api";
// axios.defaults.baseURL = 'http://192.87.18.175:8080'

axios.defaults.withCredentials = true; // 允许携带 cookie

// 添加一些额外的请求头（根据需要）
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
Vue.component("pagination", Pagination);
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === "reload") {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val); // 初始化创建的事件
        newStorageEvent.initStorageEvent(
          "setItem",
          false,
          false,
          k,
          null,
          val,
          null,
          null
        ); // 派发对象
        window.dispatchEvent(newStorageEvent);
      },
    };
    return storage.setItem(key, newVal);
  }
};
Vue.prototype.$http = axios;
Vue.use(ElementUI);
Vue.config.productionTip = false;
new Vue({
  router,
  //挂载$bus
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
  store,
  render: (h) => h(App),
}).$mount("#app");
